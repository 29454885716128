import gql from 'graphql-tag';

export const SpaceHoverPageCardQuery = gql`
	query SpaceHoverPageCardQuery($id: ID!) {
		singleContent(id: $id) {
			id
			title
			type
			subType
			status
			links {
				webui
				editui
			}
			editorVersion: properties(key: "editor") {
				nodes {
					id
					key
					value
				}
			}
			emoji: properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
				nodes {
					id
					key
					value
				}
			}
			excerpt(length: 372)
			metadata {
				currentuser {
					favourited {
						isFavourite
					}
				}
				comments {
					commentsCount
				}
				lastModifiedDate
			}
			history {
				createdBy {
					profilePicture {
						path
					}
					displayName
					... on KnownUser {
						accountId
					}
					... on User {
						accountId
					}
				}
				ownedBy {
					profilePicture {
						path
					}
					displayName
					... on KnownUser {
						accountId
					}
					... on User {
						accountId
					}
				}
			}
			space {
				id
				key
				alias
				name
			}
			likes {
				count
			}
			version {
				by {
					profilePicture {
						path
					}
					displayName
					... on KnownUser {
						accountId
					}
					... on User {
						accountId
					}
				}
			}
		}
	}
`;
