import gql from 'graphql-tag';

export const AISnippetQuery = gql`
	query AISnippetQuery(
		$contentId: ID!
		$contentType: ConfluenceContentType!
		$objectType: KnowledgeGraphObjectType!
	) {
		latestKnowledgeGraphObject(
			contentId: $contentId
			contentType: $contentType
			objectType: $objectType
		) {
			contentId
			contentType
			objectData
			createdAt
		}
	}
`;
