import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Inline, Stack } from '@atlaskit/primitives';

import { FeatureCode } from '@confluence/feature-codes';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import {
	AISnippetFooter,
	AutomationDiscoveryMessage,
	ContentIcon,
	ExcerptWithAISnippet,
	LastAction,
	PageCardContainer,
	PageLink,
	QuickActions,
	SocialProof,
	StatusLozenge,
	Subtitle,
	Title,
} from '../Items';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const PopupCard = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '400px',
	padding: `${token('space.150')} ${token('space.200')} ${token('space.200')} ${token('space.200')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Header = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const TitleContainer = styled.div({
	margin: `${token('space.200')} 0 ${token('space.050')} 0`,
	font: token('font.heading.medium'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	overflowWrap: 'break-word',
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
});

type PopupViewWithAISnippetProps = {
	shouldUseAutomationDiscovery?: boolean; // this should probably go into PageTreeContext, however since this is the only usage, it's ok to leave it here for now
	error?: boolean;
	loading: boolean;
	snippet?: string;
	isEndOfPage?: boolean;
};

export const PopupViewWithAISnippet: FC<PopupViewWithAISnippetProps> = ({
	shouldUseAutomationDiscovery,
	loading,
	snippet,
	isEndOfPage = false,
}) => {
	const isNav4Enabled = useIsNav4Enabled();
	return (
		<PageCardContainer isHoverPageCard>
			<PageLink
				shouldHideFolder
				featureAttributionCode={isEndOfPage ? FeatureCode.EOP_REC_CARD : FeatureCode.PAGE_TREE_CARD}
			/>
			<PopupCard data-testid="page-card-popup-view">
				<Header>
					<ContentIcon />
					<QuickActions actions={['edit', 'star']} />
				</Header>
				<TitleContainer>
					<Title />
				</TitleContainer>
				<Stack {...(isNav4Enabled && { space: 'space.050' })}>
					<Subtitle />
					<Inline alignBlock="center">
						<LastAction />
						<SocialProof isInlineAction />
					</Inline>
				</Stack>
				<StatusLozenge isInColumn />
				<ExcerptWithAISnippet loading={loading} snippet={snippet} isEndOfPage={isEndOfPage} />
			</PopupCard>
			{!loading && <AISnippetFooter showAIFooter={!!snippet} />}
			<AutomationDiscoveryMessage shouldUseAutomationDiscovery={shouldUseAutomationDiscovery} />
		</PageCardContainer>
	);
};
