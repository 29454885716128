import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Anchor, Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import EditorPanelIcon from '@atlaskit/icon/utility/information';

import { AISummaryFooter, FeedbackCollectorPropertiesProvider } from '@confluence/quick-summary';

import { PageCardContext } from '../../PageCardContext';

const SNIPPET_ENTRYPOINT_ID = '55dba3ae-58fa-41e0-828b-1e30fe66c0ba';
const TROUBLE_SHOOTING_URL =
	'https://support.atlassian.com/organization-administration/docs/troubleshooting-for-atlassian-intelligence/';

const i18n = defineMessages({
	noAIResultFooterText: {
		id: 'page-card.popup-view-with-ai-snippet.no-ai-footer-text',
		defaultMessage: 'Why am I seeing this?',
		description:
			'Text that shows at the bottom of a page hover card in page tree when there is no AI snippet to be shown',
	},
});

const noAIFooterWrapperStyles = xcss({
	borderTopWidth: 'border.width',
	borderTopStyle: 'solid',
	borderTopColor: 'color.border',
	paddingBlock: 'space.100',
	paddingInline: 'space.200',
	font: 'font.body.small',
});

const linkStyles = xcss({
	zIndex: 'navigation',
	color: 'color.text.subtlest',
	font: token('font.body.small'),
	textDecoration: 'none',

	':hover': {
		color: 'color.text.subtlest',
		cursor: 'pointer',
	},

	':visited': {
		color: 'color.text.subtlest',
		cursor: 'pointer',
	},
});

type AISnippetFooterProps = {
	showAIFooter?: boolean;
};

export const AISnippetFooter = ({ showAIFooter = true }: AISnippetFooterProps) => {
	const { formatMessage } = useIntl();
	const { id } = useContext(PageCardContext);

	return (
		<FeedbackCollectorPropertiesProvider entrypointId={SNIPPET_ENTRYPOINT_ID}>
			{showAIFooter ? (
				<AISummaryFooter
					analyticsProperties={{
						source: 'aiSnippet',
						additionalAnalyticsAttributes: {},
					}}
					contentId={id}
				/>
			) : (
				<Box xcss={noAIFooterWrapperStyles}>
					<Inline alignBlock="center" space="space.050">
						<EditorPanelIcon label="" color={token('color.icon.subtle')} />
						<Anchor xcss={linkStyles} href={TROUBLE_SHOOTING_URL} target="_blank">
							{formatMessage(i18n.noAIResultFooterText)}
						</Anchor>
					</Inline>
				</Box>
			)}
		</FeedbackCollectorPropertiesProvider>
	);
};
