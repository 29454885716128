import React, { useContext, useRef, useEffect } from 'react';
import { styled } from '@compiled/react';
import Markdown from 'markdown-to-jsx';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';
import { AIStreamLoading } from '@atlassian/ai-components';
import { usePopupDwellTime } from '@atlassian/popup-dwell-time';

import { PageCardContext } from '../PageCardContext';
import { PageCardsContext } from '../PageCardsContext';

import { Excerpt } from './Excerpt';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ExcerptContainer = styled.div({
	flex: '2 0 auto',
	wordWrap: 'break-word',
	marginTop: token('space.200'),
	font: token('font.body.UNSAFE_small'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	pre: {
		// We need this so code wraps in the preview, without it the code will overflow the card
		whiteSpace: 'pre-wrap',
	},
});

type ExcerptWithAISnippetProps = {
	loading: boolean;
	snippet?: string;
	isEndOfPage?: boolean;
};

export const ExcerptWithAISnippet = ({
	loading,
	snippet,
	isEndOfPage = false,
}: ExcerptWithAISnippetProps) => {
	const { width } = useContext(PageCardsContext);
	const { id: contentId, excerpt } = useContext(PageCardContext);
	const { trackAIResultView } = useAIEventsInstrumentation();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const snippetRef = useRef<HTMLDivElement | null>(null);
	const snippetValueRef = useRef(snippet);

	useEffect(() => {
		snippetValueRef.current = snippet;
	}, [snippet]);

	const { getDwellTime } = usePopupDwellTime(snippetRef, 250);

	useEffect(() => {
		return () => {
			const dwellTime = getDwellTime();
			if (dwellTime > 0) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'dwelled',
						actionSubject: 'aiSnippetHoverCard',
						source: isEndOfPage ? 'endOfPageRecommendations' : 'pageTree',
						actionSubjectId: isEndOfPage ? 'endOfPageRecommendations' : 'pageTree',
						attributes: {
							contentId,
							dwellTime,
							snippetShown: !!snippetValueRef.current,
						},
					},
				}).fire();
			}

			if (snippetValueRef.current && dwellTime >= 3000) {
				trackAIResultView({ attributes: { dwellTime } });
			}
		};
		// we only want to run this effect on un-mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return (
			<ExcerptContainer key={`${contentId}-${width}`}>
				<AIStreamLoading padding="space.0" />
			</ExcerptContainer>
		);
	}

	if (!snippet) {
		if (excerpt) {
			return <Excerpt />;
		}
		return null;
	}

	return (
		<ExcerptContainer key={`${contentId}-${width}`}>
			<div ref={snippetRef}>
				<Markdown>{snippet}</Markdown>
			</div>
		</ExcerptContainer>
	);
};
