import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';
import { ErrorMessage } from '@atlassian/ai-summary';

import { ExperienceTrackerContext, AI_SNIPPET_EXPERIENCE } from '@confluence/experience-tracker';
import { markErrorAsHandled } from '@confluence/graphql';
import { expVal } from '@confluence/feature-experiments';

import { AI_SNIPPET_METRIC } from '../../perf.config';

export enum SNIPPET_QUERY_ABORT_TYPE {
	NOT_ENOUGH_CONTENT_ERROR = 'NOT_ENOUGH_CONTENT_ERROR',
	NULL_RESPONSE = 'NULL_RESPONSE',
}

import type {
	AISnippetQuery as AISnippetQueryData,
	AISnippetQueryVariables,
} from './__types__/AISnippetQuery';
import { ConfluenceContentType, KnowledgeGraphObjectType } from './__types__/AISnippetQuery';
import { AISnippetQuery } from './AISnippetQuery.graphql';

const EXPECTED_ERROR_TYPES: ErrorMessage[] = [
	ErrorMessage.NOT_ENOUGH_CONTENT_ERROR,
	ErrorMessage.BAD_REQUEST,
];

type AISnippetCardProps = {
	contentId: string;
	contentType: string;
	children: (loading: boolean, snippet: string | undefined) => JSX.Element;
};

export const AISnippetCard = ({ contentId, children }: AISnippetCardProps) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const {
		trackAIInteractionInit,
		trackAIResultError,
		trackAIResultView,
		trackAIInteractionDismiss,
	} = useAIEventsInstrumentation();

	const objectType =
		KnowledgeGraphObjectType[expVal('consumption_ai_page_tree_snippets_2', 'type', 'snippet_v1')] ||
		KnowledgeGraphObjectType.snippet_v1;

	const { loading, data, error } = useQuery<AISnippetQueryData, AISnippetQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		AISnippetQuery,
		{
			variables: {
				contentId,
				contentType: ConfluenceContentType.PAGE,
				objectType,
			},
		},
	);

	const loadingRef = useRef(loading);
	useEffect(() => {
		loadingRef.current = loading;
	}, [loading]);

	useEffect(() => {
		trackAIInteractionInit();

		return () => {
			if (loadingRef.current) {
				trackAIInteractionDismiss();
			}
		};
		// Only on component mount/unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (loading) {
			experienceTracker.start({
				name: AI_SNIPPET_EXPERIENCE,
				attributes: {
					contentId,
				},
			});

			AI_SNIPPET_METRIC.start();
		} else {
			AI_SNIPPET_METRIC.stop();
		}
	}, [contentId, experienceTracker, loading]);

	const handleAbort = useCallback(
		(reason: SNIPPET_QUERY_ABORT_TYPE) => {
			experienceTracker.abort({ name: AI_SNIPPET_EXPERIENCE, reason });
		},
		[experienceTracker],
	);

	useEffect(() => {
		if (!loading) {
			if (error) {
				const graphQLError = error.graphQLErrors[0]; // Will only be 1
				const aiErrorMessage = ErrorMessage[graphQLError.message] || ErrorMessage.UNEXPECTED;
				trackAIResultError({
					aiErrorMessage,
					aiErrorCode: graphQLError.extensions?.statusCode || 500,
				});
				if (EXPECTED_ERROR_TYPES.includes(aiErrorMessage)) {
					handleAbort(SNIPPET_QUERY_ABORT_TYPE.NOT_ENOUGH_CONTENT_ERROR);
				} else {
					experienceTracker.fail({ name: AI_SNIPPET_EXPERIENCE, error });
				}
				markErrorAsHandled(error);
			} else if (!data?.latestKnowledgeGraphObject) {
				handleAbort(SNIPPET_QUERY_ABORT_TYPE.NULL_RESPONSE);
				markErrorAsHandled(error); // `null` responses indicates expected error from the backend, so we can ignore those
			} else if (data?.latestKnowledgeGraphObject.objectData === ' ') {
				handleAbort(SNIPPET_QUERY_ABORT_TYPE.NOT_ENOUGH_CONTENT_ERROR);
				markErrorAsHandled(error); // if there is insufficient content to generate a snippet, then we should not have such errors alert our on-call
			} else {
				trackAIResultView({ doesNotMeetMAUCriteria: true });
				experienceTracker.succeed({
					name: AI_SNIPPET_EXPERIENCE,
				});
			}
		}
	}, [data, error, experienceTracker, handleAbort, loading, trackAIResultError, trackAIResultView]);

	const snippet = useMemo(() => {
		const objectData = data?.latestKnowledgeGraphObject?.objectData;
		if (!objectData || objectData === ' ') return undefined;

		return objectData;
	}, [data]);

	return children(loading, snippet);
};
