/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ConfluenceContentType {
  BLOG_POST = "BLOG_POST",
  PAGE = "PAGE",
}

export enum KnowledgeGraphObjectType {
  snippet_v1 = "snippet_v1",
  snippet_v2 = "snippet_v2",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AISnippetQuery
// ====================================================

export interface AISnippetQuery_latestKnowledgeGraphObject {
  contentId: string;
  contentType: ConfluenceContentType;
  objectData: string;
  createdAt: string;
}

export interface AISnippetQuery {
  latestKnowledgeGraphObject: AISnippetQuery_latestKnowledgeGraphObject | null;
}

export interface AISnippetQueryVariables {
  contentId: string;
  contentType: ConfluenceContentType;
  objectType: KnowledgeGraphObjectType;
}
